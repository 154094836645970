import React, { useLayoutEffect } from "react"
import Layout from "../componets/Layout"

export default function about() {
  return (
    <Layout>
      <div>
        <h1>about</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati
          eveniet inventore sapiente sunt excepturi unde, totam assumenda, modi
          repellendus magnam quia eligendi praesentium illum expedita accusamus
          ullam, exercitationem veniam ex.
        </p>
      </div>
    </Layout>
  )
}
